.footer-container {
  background: #0c0f16;
  padding: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: top;
  justify-content: center;
  gap: 200px;
}

.footer-title {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 1.4em;
  word-wrap: break-word;
  padding-bottom: 15px;
}
li {
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 13px;
}

.text li {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.text li:hover {
  color: #12c2e9;
  cursor: pointer;
  text-decoration: underline;
}

.contact-us-icons svg {
  width: 20px;
  height: 20px;
  color: #fff;
}
.contact-us-icons svg:hover {
  color: #12c2e9;
}
li div {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.8em;
  display: flex;
  flex-direction: column;
}
li div:hover {
  color: #12c2e9;
  cursor: pointer;
}

.copyright {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  line-height: 2em;
  letter-spacing: 2.5px;
}
.copyright:hover {
  color: #12c2e9;
  cursor: pointer;
}
.social-icons {
  display: flex;
  gap: 10px;
  padding-bottom: 30px;
}

.social-svg-icon:hover {
  fill: #12c2e9 !important;
}
.social-icon {
  height: 40px !important;
  width: 40px !important;
}

@media (max-width: 768px) {
  .footer-title {
    font-size: 20px;
  }
}
@media (max-width: 425px) {
  .contact-us-icons svg {
    width: 15px;
    height: 15px;
  }
  .social-icon {
    height: 30px !important;
    width: 30px !important;
  }
}
@media (max-width: 375px) {
  .contact-us-icons svg {
    width: 15px;
    height: 15px;
  }
  .social-icon {
    height: 30px !important;
    width: 30px !important;
  }
}
