* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body {
  background: #11141b;
  
} */

h1,h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

h3, h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

p{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.2px;
  font-size: 16px;
}
li, button{
   font-family: "Roboto", sans-serif;

}


