.header-container {
  width: 100%;
  height: 60vh;
  position: fixed;
  background: #1a2a34;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10;
  padding: 80px;
}

.header-container h1 {
  font-size: 60px;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(to right, #12c2e9, #d641a7, #f64f59);
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px transparent;
  text-align: center;
}
.header-container p{
  text-align: center;
}