.happyclients-container {
  background: #11141b;
  color: #fff;
  width: 100%;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}
.happyclients-container .item {
  text-align: center;
}
.items-container {
  display: flex;
  gap: 230px;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.item svg {
  height: 40px;
  width: 40px;
  color: #12c2e9;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .happyclients-container {
    padding: 60px;
    padding-top: 20px;
  }
  .item h1 {
    font-size: 1.8em;
  }
  .text {
    font-size: 1em;
  }
  .item svg {
    height: 30px;
    width: 30px;
  }
  .items-container {
    gap: 90px;
  }
}
@media (max-width: 425px) {
  .happyclients-container {
    padding-top: 40px;
  }
  .item h1 {
    font-size: 16px;
  }
  .text {
    font-size: 14px;
  }
  .item svg {
    height: 25px;
    width: 25px;
  }
  .items-container {
    gap: 60px;
  }
}
@media (max-width: 375px) {
  .happyclients-container {
    padding: 40px 20px;
  }
  .item h1 {
    font-size: 15px;
  }
  .text {
    font-size: 12px;
  }
  .item svg {
    height: 20px;
    width: 20px;
  }
  .items-container {
    gap: 30px;
  }
}
@media (max-width: 320px) {
  .item h1 {
    font-size: 1.5em;
  }
  .text {
    font-size: 0.7em;
  }

  .items-container {
    gap: 20px;
  }
}
