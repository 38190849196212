.NavbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 30px;
  background-color: #222;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 95%;
  height: 80px;
  border-radius: 13px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1200;
}

.logo {
  color: #fff;
  font-size: 14px;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 100%;
  max-height: 100%;
}

.menu-icons {
  display: none;
}

.menuBars {
  color: #fff;
  height: 30px;
  width: 30px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.nav-menu.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  left: 0;
  background: #11141b;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  border-radius: 13px;
  width: 100%;
  padding: 1rem;
}

.nav-links {
  text-decoration: none;
  color: #fff;
  padding: 0.7rem 1rem;
  font-size: 16px;
  white-space: nowrap;
}

.nav-links:hover {
  background: #13c2e9;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.nav-links-donate-btn {
  white-space: nowrap;
  text-decoration: none;
  background: #fff;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 1px solid #fff;
  color: #222;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 500;
}

.nav-links-donate-btn:hover {
  background: #fff;
  color: #13c2e9;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 850px) {
  .menu-icons {
    display: block;
  }
  
  .nav-menu {
    display: none;
  }
  
  .nav-menu.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .nav-links {
    display: block;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
  }
  
  .nav-links-donate-btn {
    padding: 0.8rem;
    display: block;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    width: 80%;
    margin: auto;
  }
}
