.featured-projects-container {
  background: #11141b;
  text-align: center;
  color: #fff;
  width: 100%;
  padding: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.recent-projects {
  font-size: 38px;
  line-height: 1.2;
  letter-spacing: 2px;
  font-weight: 700;
  padding-bottom: 80px;
}
.project-image {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.active {
  display: inline-block;
}
.inactive {
  display: none;
}
.project-desc {
  background: #0c0f16;
  position: absolute;
  padding: 15px 30px;
  bottom: 9px;
  left: 50%;
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
  transform: translateX(-50%);
  transition: all 0.4s;
  text-align: center;
}
.project-desc h6 {
  color: #12c2e9;
  visibility: visible;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  background: linear-gradient(
    90deg,
    rgba(18, 194, 233, 1) 3%,
    rgba(214, 65, 167, 1) 10%,
    rgba(246, 79, 105, 1) 80%
  );
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px transparent;
}
.project-desc a {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  color: #fff;
  text-decoration: none;
  transition: ease 0.3s;
}
.arrows {
  position: absolute; 
  bottom: 20px;
  width: 100%; 
  display: flex;
  justify-content: space-between; 
}
.arrows-prev,
.arrows-next {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  bottom: 9px;
  padding: 1rem;
  border: none;
  font-size: 30px;
  font-weight: bold;
  background: none;
}
.arrows-prev:hover,
.arrows-next:hover {
  color: #fff;
  transition: all 0.5s ease-in;
}

.arrows svg {
  color: #fff;
  height: 25px;
  width: 25px;
}

.arrow-btn {
  position: relative;
  padding: 7px;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 13px;
  font-weight: 300;
  border: none;
  outline: none;
}
.arrow-btn:hover {
  color: #888;
  transition: all 0.5s ease-in;
}

@media (max-width: 1440px) {
  .featured-projects-container {
    height: auto;
  }

  .arrows-prev,
  .arrows-next {
    bottom: 115px;
  }

  .arrows-prev {
    left: 90px;
  }
  .arrows-next {
    right: 90px;
  }
}
@media (max-width: 768px) {
  .featured-projects-container {
    height: auto;
    padding: 20px;
  }
  .project-image {
    width: 100%;
  }
  .recent-projects {
    font-size: 30px;
    padding-bottom: 60px;
  }

  .arrows-prev,
  .arrows-next {
    bottom: 10px;
  }

  .arrows-prev {
    left: 50px;
  }
  .arrows-next {
    right: 50px;
  }
}
@media (max-width: 425px) {
  .featured-projects-container {
    height: auto;
  }

  .recent-projects {
    font-size: 28px;
    padding-bottom: 40px;
  }

  .project-desc {
    width: 70%;
    bottom: 20px;
  }
 .arrows-prev,
  .arrows-next {
    bottom: 50px;
  }
  .arrows-prev {
    left: 5px;
  }
  .arrows-next {
    right: 5px;
  }
  
}
@media (max-width: 375px) {
 

  .recent-projects {
    font-size: 24px;
    padding-bottom: 35px;
  }

  .arrows-prev {
    left: 10px;
  }
  .arrows-next {
    right: 10px;
  }
  
}
@media (max-width: 320px) {

  .project-image {
    width: 90%;
  }

  .arrows-prev {
    left: 5px;
  }
  .arrows-next {
    right: 5px;
  }
}
