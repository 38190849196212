.main-container {
  background: #0c0f16;
  color: #fff;
  width: 100%;
  padding: 100px 60px;

}
.container {
  display: flex;
  gap: 40px;
  padding: 40px;
}
.left, .right{
  flex: 1;
}


.left h2 {
  font-size: 31px;
  font-weight: 600;
  line-height: 2.5rem;
  padding-bottom: 20px;
  text-transform: capitalize;
}
.left h3 {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 1;
  padding-bottom: 20px;
  font-weight: 500;
  text-transform: uppercase;
  background: linear-gradient(
    90deg,
    rgba(18, 194, 233, 1) 3%,
    rgba(214, 65, 167, 1) 10%,
    rgba(246, 79, 105, 1) 80%
  );
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.left p {
  color: #fff;
  padding-bottom: 20px;
}
.left ul {
  list-style: none;
  padding-bottom: 30px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 200px;
}
.left li {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.5px;
}
.left svg {
  border: 1px solid #6ec1e4;
  height: 20px;
  width: 20px;
  padding: 2px;
  color: #12c2e9;
  border-radius: 50%;
}
.left button {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  fill: #ffffff;
  color: #ffffff;
  border-radius: 50px 50px 50px 50px;
  padding: 12px 35px 12px 35px;
  background: linear-gradient(90deg, #12c2e9 0%, #c471ed 50%, #f64f59 100%);
}

.right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  z-index: 10;
}

@media (max-width: 1024px) {
  .container {
    padding: 20px;
    height: 100vh;
  }
  .left h2 {
    font-size: 38px;
    line-height: 2.5rem;
  }
  .left h3 {
    font-size: 20px;
    letter-spacing: 2.5px;
  }
  .left p,
  .left li {
    font-size: 16px;
  }
  .left svg {
    height: 25px;
    width: 25px;
  }
  .left button {
    font-size: 15px;
    border-radius: 50px 50px 50px 50px;
    padding: 15px 32px 15px 32px;
  }
  .right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left 50% bottom 30px;
    z-index: 10;
  }
}
@media (max-width: 768px) {
  .main-container {
    padding: 60px;
}
  .container {
    padding: 20px;
    flex-direction: column;
    height: auto;
  
  }
  .left h2 {
    font-size: 34px;
    line-height: 2.2rem;
  }
  .left h3 {
    font-size: 18px;
    letter-spacing: 2px;
  }
  .left p,
  .left li {
    font-size: 16px;
  }
  .left svg {
    height: 25px;
    width: 25px;
  }
  .left button {
    font-size: 14px;
    border-radius: 50px 50px 50px 50px;
    padding: 10px 25px 10px 25px;
  }
  .right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left 50% bottom 30px;
    z-index: 10;
  }
}
@media (max-width: 425px) {
  .main-container{
    padding: 20px;
  }
  .container {
    padding: 20px;
    flex-direction: column;
  
  }
  .left h2 {
    font-size: 30px;
    line-height: 2.2rem;
  }
  .left h3 {
    font-size: 16px;
    letter-spacing: 2px;
  }
  .left ul {
    gap: 100px;
  }
  .left p,
  .left li {
    font-size: 15px;
  }
  .left svg {
    height: 20px;
    width: 20px;
  }
  .left button {
    font-size: 14px;
    border-radius: 50px 50px 50px 50px;
    padding: 10px 25px 10px 25px;
  }
  .right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left 50% bottom 30px;
    z-index: 10;
  }
}
@media (max-width: 375px) {
  
  .left h2 {
    font-size: 26px;
    line-height: 2.2rem;
  }
  .left h3 {
    font-size: 14px;
    letter-spacing: 2px;
  }
  .left ul {
    gap: 80px;
  }
  .left p,
  .left li {
    font-size: 14px;
  }
  .left svg {
    height: 20px;
    width: 20px;
  }
  .left button {
    font-size: 14px;
    border-radius: 50px 50px 50px 50px;
    padding: 10px 25px 10px 25px;
  }
  .right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left 50% bottom 30px;
    z-index: 10;
  }
}
@media (max-width: 320px) {

  .left h2 {
    font-size: 24px;
    line-height: 2rem;
  }
  .left h3 {
    font-size: 14px;
    letter-spacing: 2.5px;
  }
  .left ul {
    gap: 40px;
  }
  .left p,
  .left li {
    font-size: 14px;
  }
  .left svg {
    height: 20px;
    width: 20px;
  }
  .left button {
    font-size: 14px;
    border-radius: 50px 50px 50px 50px;
    padding: 10px 25px 10px 25px;
  }
  .right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left 50% bottom 30px;
    z-index: 10;
  }
}
