.cta-container {
  padding: 80px 60px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}

h2 {
  color: #12c2e9;
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.2em;
  letter-spacing: 5px;
  padding-bottom: 10px;
}
.cta-h2-one {
  font-size: 4vw;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}
.cta-h2-two {
  color: #ffffff;
  font-size: 4vw;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2em;
  background: linear-gradient(90deg, #12c2e9 0%, #c471ed 50%, #f64f59 100%);
}

button {
  background: transparent;
  padding: 12px 35px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  color: #000;
  border: 2px solid #fff;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 2px;
  color: #fff;
}

@media (max-width: 768px) {
  .cta-container {
    top: 3560px;
    padding: 100px 20px;
  }
  h2 {
    font-size: 14px;
  }
  .cta-h2-one,
  .cta-h2-two {
    font-size: 3.8vw;
  }
}
@media (max-width: 425px) {
  .cta-container {
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  button {
    padding: 10px 20px;
    margin-bottom: 30px;
  }
}
@media (max-width: 375px) {
  .cta-container {
    padding: 60px 20px;
  }
  button {
    padding: 10px 20px;
  }
}
