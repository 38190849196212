.contactform-container {
  background: #11141b;
  color: #fff;
  width: 100%;
  padding: 60px;
}
.contactform-container h2 {
  text-align: center;
}
.contactform-container .info {
  display: flex;
  gap: 60px;
}
.contactform-container .info .form {
  flex: 1;
}
.contactform-container .info .contact-info {
  flex: 1;
}
.contactform-container h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
  line-height: 1;
  padding-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  background: linear-gradient(
    90deg,
    rgba(18, 194, 233, 1) 3%,
    rgba(214, 65, 167, 1) 10%,
    rgba(246, 79, 105, 1) 80%
  );
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.contactform-container .info .form form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.contactform-container .info .form form input {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
  border: none;
  outline: none;
}
.contactform-container .info .form form textarea {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
  border: none;
  outline: none;
}
input::placeholder,
textarea::placeholder {
  font-size: 16px;
  font-weight: 600;
  color: #11141b;
}
button {
  border: none;
  border-radius: 5px;
  padding: 12px 35px;
  background: linear-gradient(90deg, #12c2e9 0%, #c471ed 50%, #f64f59 100%);
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease;
}

button:hover {
  background: #fff;
  color: #12c2e9;
}
.contactform-container .info .contact-info .item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.item svg {
  height: 25px;
  width: 25px;
  transition: color 0.3s ease;
}
.contact-info p:hover {
  color: #12c2e9;
}
@media (max-width: 425px) {
  .contactform-container {
    padding: 40px;
  }
  .info {
    flex-direction: column;
  }
  .rc-anchor-normal {
    height: 74px;
    width: 250px;
  }
}
