.portfolio-cards-container {
  background-color: #11141b;
  color: #fff;
  width: 100%;
  padding: 80px 60px;
}
.portfolio-filter {
  display: block;
  text-align: center;
}
.portfolio-filter div {
  padding: 15px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 30px;
  display: inline-block;
}
.portfolio-filter span {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 20px;
  cursor: pointer;
}

.gallery {
  display: flex;
  gap: 60px;
  align-items: center;
  padding-top: 50px;
}
.gallery .item {
  width: 100%;
  height: 300px;
}
.gallery .item .item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: ease-in 0.5s;
  cursor: pointer;
}
.gallery .item .item-img img:hover {
  transform: scale(1.1);
}

.gallery .item .content {
  text-align: center;
  padding-top: 20px;
}
.gallery .item .content h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1.4em;
  word-wrap: break-word;
}

.gallery .item .content span {
  color: #999;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 12px;
  background: linear-gradient(
    to right,
    var(--color-primary),
    var(--color-secondary),
    var(--color-ternary)
  );
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 768px) {
  .portfolio-cards-container {
    padding: 60px;
    padding-bottom: 20px;
  }
}

@media (max-width: 425px) {
  .gallery .item {
    height: auto;
  }
  .gallery .item .content h6 {
    font-size: 16px;
  }
}
@media (max-width: 375px) {
  .gallery {
    gap: 20px;
    padding-top: 20px;
  }
  .portfolio-filter div {
    display: inline;
    flex-wrap: wrap;
  }
  .portfolio-cards-container {
    padding: 40px 20px;
  }
}
